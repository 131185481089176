import { ApiError } from '../../errors';
import { EnvService } from '../env-service';
import { CCApiService } from './api-service';
import {
  CustomMachineLearningDeployment,
  CustomMachineLearningModel,
  DeploymentLastUsed,
  NewDeploymentFormData,
  NewModelFormData,
  ServiceAccountCredentials,
  UIConfig,
  ApplicationType,
  Application,
  LucidworksAIPredictions
} from './interfaces';
import * as _ from 'lodash';

export class LucidworksAIService {
  static ApiService: CCApiService;
  static lucidworksAIApi: string;
  static apiBasePath: string;
  static customerId: string;
  static analyticsApi: string;
  static validationApi: string;
  static applicationsAPI: string;

  static init(config: UIConfig, customerId: string) {
    this.ApiService = new CCApiService(config.authClientId, config.authIssuerUri);
    this.apiBasePath = config.apiBasePath;
    this.customerId = customerId;
    this.lucidworksAIApi = `${config.apiBasePath}/customers/${this.customerId}/ai`;
    this.analyticsApi = `${config.apiBasePath}/analytics/${customerId}/ml/predictions`;
    this.validationApi = `${config.apiBasePath}/customers/${customerId}/ai/credentials/validate`;
    this.applicationsAPI = `${config.apiBasePath}/customers/${customerId}/solutions`;
  }

  static async getModels(filter): Promise<any> {
    const url = `${this.lucidworksAIApi}/models?${new URLSearchParams(
      filter
    )}`;
    return this.ApiService.performFetch(url).then(result => result);
  }

  static async getDeployments(): Promise<CustomMachineLearningDeployment[]> {
    return this.ApiService.performFetch(`${this.lucidworksAIApi}/deployments`).then(result => result);
  }

  static async getModel(modelId: string, metrics: boolean = true): Promise<CustomMachineLearningModel> {
    return this.ApiService.performFetch(`${this.lucidworksAIApi}/models/${modelId}?metrics=${metrics}`).then(
      result => result
    );
  }

  static async getModelDeployments(modelId: string): Promise<any> {
    return this.ApiService.performFetch(`${this.lucidworksAIApi}/models/${modelId}/deployments?size=100`).then(result => result);
  }

  static async getDeploymentsLastUsed(deploymentId: string): Promise<DeploymentLastUsed> {
    return this.ApiService.performFetch(`${this.analyticsApi}/latest?deployment_id=${deploymentId}`).then(
      result => result
    );
  }

  static async newModel(model: NewModelFormData): Promise<CustomMachineLearningModel> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(model),
    };
    return this.ApiService.performFetch(`${this.lucidworksAIApi}/models`, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async deployModel(deployment: NewDeploymentFormData): Promise<void> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(deployment),
    };
    return this.ApiService.performFetch(`${this.lucidworksAIApi}/deployments`, fetchOptions).catch(
      this._mapApiErrorCodeToMessage
    );
  }

  static async validateServiceAccountCredentials(credentials: ServiceAccountCredentials): Promise<void> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(credentials),
    };
    return this.ApiService.performFetch(`${this.validationApi}`, fetchOptions).catch(this._mapApiErrorCodeToMessage);
  }

  static async deleteModel(modelId: string): Promise<void> {
    const url = `${this.lucidworksAIApi}/models/${modelId}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static async deleteDeployment(deploymentId: string): Promise<void> {
    const url = `${this.lucidworksAIApi}/deployments/${deploymentId}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static async getModelUsage({config}): Promise<any> {
    const fetchOptions = {
      method: 'GET',
    };

    const queryParams = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      ...config
    };

    return this.ApiService.performFetch(`${this.analyticsApi}/modelusage?${new URLSearchParams(queryParams)}`, fetchOptions).then(
      result => result
    );
  }

  static async getModelUsageFacets(): Promise<any> { 
    return this.ApiService.performFetch(`${this.analyticsApi}/modelusage/facets`).then(
      result => result
    );
  }

  static async getLucidworksAIEndpoints(): Promise<{content: Application[]}> {
    return this.ApiService.performFetch(this.applicationsAPI).then(apps => {
      const aiApps = _.filter(apps, {type: ApplicationType.AI_ENDPOINT});
      return {content: aiApps};
    });
  }

  static async getLucidworksAIEndpoint(id: string): Promise<Application> {
    return this.ApiService.performFetch(`${this.applicationsAPI}/${id}`).then(
      app => app
    );
  }

  static async createLucidworksAIEndpoint(data: Application): Promise<any> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify(data),
    };
    return this.ApiService.performFetch(this.applicationsAPI, fetchOptions);
  }

  static async deleteLucidworksAIEndpoint(id: string): Promise<any> {
    const url = `${this.applicationsAPI}/${id}`;
    const fetchOptions = {
      method: 'DELETE',
    };
    return this.ApiService.performFetch(url, fetchOptions);
  }

  static async getCells(type: string): Promise<any> { 
    const queryParams = {
      type
    };
    return this.ApiService.performFetch(`${this.apiBasePath}/cells?${new URLSearchParams(queryParams)}`).then(
      result => result
    );
  }

  static async getUseCases(id: string): Promise<any> {
    const env = EnvService.isProd() ? 'com' : 'dev';
    return this.ApiService.performFetch(`https://${id}.applications.lucidworks.${env}/ai/usecase`).then(
      result => result
    );
  }

  static async getPrediction(applicationId, useCase: string, modelName: string, conversation: {}, signal: AbortSignal): Promise<LucidworksAIPredictions> {
    const fetchOptions = {
      method: 'POST',
      body: JSON.stringify({batch: [{ text: JSON.stringify(conversation)}], useCaseConfig: {dataType: "json_prompt"}}),
      signal
    };
    const env = EnvService.isProd() ? 'com' : 'dev';
    return this.ApiService.performFetch(`https://${applicationId}.applications.lucidworks.${env}/ai/prediction/${useCase}/${modelName}`, fetchOptions).then(
      result => result
    );
  }

  static _mapApiErrorCodeToMessage(error) {
    if (error instanceof ApiError && error.message === 'E0000038') {
      error.message = 'The user cannot be added. Please contact customer support.';
    }
    throw error;
  }
}
